<template>
  <div class="flex flex-col h-full">
    <FormModal :title="$t('title')">
      <template #progressbar>
        <div class="flex justify-center py-8">
          <div class="flex space-x-3">
            <span class="rounded block h-1 bg-primary w-12" />
            <span class="rounded block h-1 bg-lightgrey w-12" />
            <span class="rounded block h-1 bg-lightgrey w-12" />
            <span class="rounded block h-1 bg-lightgrey w-12" />
          </div>
        </div>
      </template>

      <template #body>
        <AddressInformationFormStep
          ref="step"
          :progress-value="progressValue"
        />
        <span class="text-accent text-xs">
          * {{ $t('global.requiredFields') }}
        </span>
      </template>
    </FormModal>
  </div>
</template>

<script>
import FormModal from 'chimera/all/themes/jupiter/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import AddressInformationFormStep from '~/components/form/steps/addressInformation/AddressInformationFormStep'

export default {
  components: {
    AddressInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Adresgegevens',
      path: '/offertes-aanvragen/adresgegevens',
      progressValue: 20,
      checkoutStep: 2,
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Jouw adresgegevens",
    "step-title": "Adresgegevens",
    "step-1": "Jouw adresgegevens",
    "step-2": "Contactgegevens"
  },
  "en-GB": {
    "title": "Your address details",
    "step-title": "Property location",
    "step-1": "About your property",
    "step-2": "About you"
  }
}
</i18n>
